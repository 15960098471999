const projects = {
	project1: {
		name: 'GODADDY',
		brand: 'GODADDY',
		company: 'GODADDY',
		year: '2022-2023',
		desc:
			'Developed a domain search tool for multiple GoDaddy European brands, including 123Reg, Host Europe, Heart Internet, and Domain Factory. The project involved building the tool with a modern tech stack (ReactJS, TypeScript) and creating a flexible design system to maintain each brand’s unique UI theme. The stack included ReactQuery for data fetching, Storybook for UI component development, CSS-in-JS for styling, and comprehensive testing with Jest and React Testing Library. The tool was built to integrate with WordPress CMS.',
		techstack: 'ReactJS, TypeScript, ReactQuery, Storybook, CSSinJS, Jest, WordPress CMS',
		image: '/images/godaddy.jpg',
		mobileImg: '/images/123-reg-mobile.jpg',
		tabletImg: '/images/123-reg-tablet.jpg',
		desktopImg: '/images/123-reg-desktop.jpg',
		mobile: true,
		tablet: true,
		desktop: true,
		status: 'available'
	},
	project2: {
		name: 'NEW LOOK',
		brand: 'NEW LOOK  #3',
		company: 'Wunderman Thompson Commerce',
		year: '2021',
		desc:
			'Contributed to front-end development for New Look’s new website, built with NextJS. Developed a UI component library using Storybook, enabling consistent and reusable components across the platform. The project tech stack included NextJS, ReactJS, JavaScript, SCSS, Storybook UI, and the Hybris CMS e-commerce platform.',
		techstack: 'NextJs, ReactJS, JS, SCSS, CSS, Storybook, Hybris CMS',
		image: '/images/newlook2-2022.jpg',
		mobileImg: '/images/newlook2-mobile.jpg',
		tabletImg: '/images/newlook2-tablet.jpg',
		desktopImg: '/images/newlook2-desktop.jpg',
		mobile: true,
		tablet: true,
		desktop: true,
		status: 'available'
	},
	project3: {
		name: 'SELFRIDGES',
		brand: 'SELFRIDGES',
		company: 'Wunderman Thompson Commerce',
		year: '2021',
		desc:
			'Worked as part of a large development team on Selfridges’ new website, built with NextJS. Contributed to the development of a new UI component library using Storybook, ensuring reusability and consistency across the site. The project utilized a tech stack of NextJS, ReactJS, TypeScript, Styled Components, Material UI, and Storybook UI, with unit testing done using Jest and React Testing Library. The site was integrated with the Adobe Experience Manager e-commerce platform.',
		techstack: 'NextJs, ReactJS, Typescript, Styled Components, Material UI, Storybook UI, Jest, Adobe Experience Manager e-commerce Platform',
		image: '/images/selfridges.webp',
		mobileImg: '/images/selfridges-mobile.jpg',
		tabletImg: '/images/selfridges-tablet.jpg',
		desktopImg: '/images/selfridges-desktop.jpg',
		mobile: true,
		tablet: true,
		desktop: true,
		status: 'available'
	},
	project4: {
		name: 'TED BAKER',
		brand: 'TED BAKER',
		company: 'Wunderman Thompson Commerce',
		year: '2018-2021',
		desc:
			'Served as the main front-end engineer for Ted Baker’s website, responsible for implementing new features and updates for the brand. Led a performance optimization and usability improvement project in collaboration with Google at their London offices. The project utilized a tech stack of JSP, JavaScript (AngularJS framework), CSS, and the Hybris CMS e-commerce platform.',
		techstack: 'JSP, JS, CSS, Hybris CMS',
		image: '/images/ted-baker-logo.jpg',
		mobileImg: '/images/ted-mobile.jpg',
		desktopImg: '/images/ted-desktop.jpg',
		mobile: true,
		tablet: false,
		desktop: true,
		status: 'available'
	},
	project5: {
		name: 'THE WHITE COMPANY',
		brand: 'THE WHITE COMPANY',
		company: 'Salmon (Neoworks)',
		year: '2017-2018',
		desc:
			'Focused primarily on bug fixing and search integration for a new responsive site. Development involved using JSP, JavaScript, CSS, and the Hybris CMS e-commerce platform.',
		techstack: 'HTML, CSS, JS, JSP, Hybris CMS',
		image: '/images/white-company-logo.png',
		mobileImg: '/images/twc-mobile.jpg',
		desktopImg: '/images/twc-desktop.jpg',
		mobile: true,
		tablet: false,
		desktop: true,
		status: 'available'
	},
	project6: {
		name: 'NEW LOOK',
		brand: 'NEW LOOK  #2',
		company: 'Salmon - Neoworks',
		year: '2017-2018',
		desc:
			'Worked on New Look’s Phase 2, where I developed numerous new features and refactored old components. Mentored junior front-end developers on the project. Key features included the New Look Gift Card, Store Card, Address Finder, Checkout Delivery, Checkout Collection, Delivery Pass, and more. The project utilized JavaScript (AngularJS framework), CSS with SASS (following the BEM methodology), and was built on the Hybris CMS e-commerce platform.',
		techstack: 'HTML, CSS, JS, JSP, Hybris CMS.',
		image: '/images/newlook-logo-phase1.jpg',
		mobileImg: '/images/newlook-mobile.jpg',
		desktopImg: '/images/newlook-desktop.jpg',
		tabletImg: '/images/newlook-tablet.jpg',
		mobile: true,
		tablet: true,
		desktop: true,
		status: 'available'
	},
	project7: {
		name: 'NEW LOOK',
		brand: 'NEW LOOK  #1',
		company: 'Salmon - Neoworks',
		year: '2017-2018',
		desc:
			'Joined the project from the start during New Look’s Phase 1, spending a year developing core UI components for the site. Built key user interfaces, including the checkout, header, footer, and more. The project leveraged JavaScript (AngularJS framework), CSS with SASS (using the BEM approach), and was built on the Hybris CMS e-commerce platform.',
		techstack: 'HTML, CSS, JS, JSP, Hybris CMS',
		image: '/images/newlook-card.png',
		mobileImg: '/images/nlk-mobile-phase1.jpg',
		desktopImg: '/images/nlk-desktop-phase1.jpg',
		tabletImg: '/images/nlk-tablet-phase1.jpg',
		mobile: true,
		tablet: true,
		desktop: true,
		status: 'available'
	},
	project8: {
		name: 'LLOYDS PHARMACY',
		brand: 'LLOYDS PHARMACY',
		company: 'SALMON (Watford)',
		year: '2015',
		desc:
			'Frontend development for the Sales Center Management App on Lloyds Pharmacy’s e-commerce portal. Utilized JavaScript with the Backbone JS Framework and Backbone Marionette, along with SASS and HTML. The integration was completed on the Websphere Commerce Platform. The project spanned 6 months.',
		techstack: 'HTML, CSS/SASS, JS, JSP, IBM WEBSPHERE COMMERCE',
		image: '/images/lloyds-pharmacy-logo.png',
		mobileImg: '/images/lloyds-mobile.jpg',
		desktopImg: '/images/lloyds-desktop.jpg',
		mobile: true,
		tablet: true,
		desktop: true,
		status: 'available'
	},
	project9: {
		name: 'THE BODY SHOP',
		brand: 'THE BODY SHOP',
		company: 'Reply',
		year: '2014-2015',
		desc:
			'Development of the new responsive website using Angular Js + Handlebars. Also using Javascript, CSS and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, JSP, Hybris CMS',
		image: '/images/thebodyshop-logo.jpg',
		mobileImg: '/images/bodyshop-mobile.jpg',
		desktopImg: '/images/bodyshop-desktop.jpg',
		mobile: true,
		tablet: true,
		desktop: true,
		status: 'available'
	},
	project10: {
		name: 'TABAZON.CH',
		brand: 'BRITISH AMERICAN TOBACCO',
		company: 'Reply',
		year: '2014-2015',
		desc:
			'Complete re-styling of Tabazon.ch (BRITISH AMERICAN TOBACCO) desktop website creating a new responsive mobile view. Development using JSP, Javascript, CSS and Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, JSP, Hybris CMS',
		image: '/images/bat-card.jpg',
		mobileImg: '/images/bat-mobile.jpg',
		mobile: true,
		tablet: false,
		desktop: false,
		status: 'available'
	},
	project11: {
		name: 'ACCESSORIZE SS14 FUSION SPRING SUMMER',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2014',
		desc:
			'Development of the SS14 Fusion Spring Summer 2014 online catalogue, doing a responsive template for mobile and tablet with Javascript driven animations. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, Hybris CMS',
		image: '/images/fusion-card.jpg',
		mobileImg: '/images/fusion.jpg',
		tabletImg: '/images/fusion-tablet.jpg',
		mobile: true,
		tablet: true,
		desktop: false,
		status: 'available'
	},
	project12: {
		name: 'MONSOON JET SET STYLE GUIDE',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2014',
		desc:
			'Development of a responsive template for mobile with tab based views fully extensible and customizable. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, Hybris CMS',
		image: '/images/beach-france-card.jpg',
		mobileImg: '/images/beach-france-full.jpg',
		mobile: true,
		tablet: false,
		desktop: false,
		status: 'available'
	},
	project13: {
		name: 'MONSOON DENIM LOOKBOOK',
		image: '/images/denim-card.jpg',
		mobileImg: '/images/denim-full.jpg',
		tabletImg: '/images/denim-tablet-full.jpg',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2013-2014',
		desc:
			'Development of a responsive template for mobile and tablet with Javascript driven animations. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, Hybris CMS',
		mobile: true,
		tablet: true,
		desktop: false,
		status: 'available'
	},
	project14: {
		name: 'MONSOON THE BEACH SHOP',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2014',
		desc:
			'Development of a responsive template for mobile. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, Hybris CMS',
		image: '/images/beach-card.jpg',
		mobileImg: '/images/beach_full.jpg',
		mobile: true,
		tablet: false,
		desktop: false,
		status: 'available'
	},
	project15: {
		name: 'MONSOON WOMEN LANDING PAGE',
		image: '/images/women-card.jpg',
		mobileImg: '/images/women-full.jpg',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2013-2014',
		desc:
			'Weekly Updates on a responsive template for mobile and tablet. Page with multi-language support, translated in 5 languages. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, Hybris CMS',
		mobile: true,
		tablet: false,
		desktop: false,
		status: 'available'
	},
	project16: {
		name: 'ACCESSORIZE BAFTA COMPETITION',
		image: '/images/bafta-card.jpg',
		mobileImg: '/images/bafta-mobile.jpg',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2014',
		desc:
			'Development of a responsive template for mobile and tablet to enter the Accessorize Bafta competition. Saving data to MySQL DB using PHP and SQL. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, PHP, SQL, Hybris CMS',
		mobile: true,
		tablet: false,
		desktop: false,
		status: 'available'
	},
	project17: {
		name: 'ACCESSORIZE 12 DAYS OF JOY',
		image: '/images/xmas.jpg',
		tabletImg: '/images/12-days.jpg',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2013',
		desc:
			'Development of a calendar page for tablet with HTML, Javascript, CSS. The competition allowed to win each day a different prize, counting down 12 days until Christmas. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, Hybris CMS',
		mobile: false,
		tablet: true,
		desktop: false,
		status: 'available'
	},
	project18: {
		name: 'OPEN REPLY INVENTORY',
		image: '/images/openreply-card.png',
		mobileImg: '/images/openreply-mobile.png',
		desktopImg: '/images/openreply-desktop.png',
		brand: 'OPEN REPLY UK',
		company: 'Reply',
		year: '2014',
		desc:
			'Development of a responsive PHP / MySQL based inventory for all devices at Open Reply Office. Development was completed in one day.',
		techstack: 'PHP, HTML, CSS, JS, MySQL, SQL',
		mobile: true,
		tablet: false,
		desktop: true,
		status: 'available'
	},
	project19: {
		name: 'MONSOON REWARD CARD',
		image: '/images/reward-card.jpg',
		mobileImg: '/images/reward-card-mobile-full.jpg',
		tabletImg: '/images/reward-card-full.jpg',
		brand: 'MONSOON ACCESSORIZE',
		company: 'Reply',
		year: '2014',
		desc:
			'Development of two different fluid templates for mobile and tablet. The page was tested and integrated into Hybris CMS e-commerce Platform.',
		techstack: 'HTML, CSS, JS, Hybris CMS',
		mobile: true,
		tablet: true,
		desktop: false,
		link: 'http://uk.monsoon.co.uk/view/content/reward-card',
		status: 'available'
	},
	project20: {
		name: 'EDF ENERGY B2B',
		brand: 'EDF ENERGY',
		company: 'AIRLOCK / LEO BURNETT',
		year: '2012',
		desc:
			'Development of the EDF-Energy B2B website, using parallax, horizontal scrolling, and several advanced UI techniques. Cross-browser website, supporting up to IE8. Also done lots of bug fixing, UI polishing, testing.',
		techstack: 'HTML, CSS, JS, PHP',
		image: '/images/edf-card2.jpg',
		desktopImg: '/images/edf-full.png',
		mobile: false,
		tablet: false,
		desktop: true,
		link: 'http://alexincarnati.com/projects/edf-energy/',
		status: 'available'
	},
	project21: {
		name: 'MINI INTERNATIONAL IPAD APP',
		brand: 'MINI',
		company: 'Airlock / Leo Burnett',
		year: '2012',
		desc:
			'Development of a Phonegap HTML5 JS Hybrid App for Ipad. Mainly bug fixing, UI styling, translating content.',
		techstack: 'HTML, CSS, JS, Phonegap, PHP',
		image: '/images/mini-card.jpg',
		tabletImg: '/images/mini-full.png',
		mobile: false,
		tablet: true,
		desktop: false,
		status: 'available'
	},
	project22: {
		name: 'TV HACKFEST PRESENTATION',
		brand: 'Leo Burnett',
		company: 'Airlock / Leo Burnett',
		year: '2013',
		desc: 'Development of an interactive presentation for TV Hackfest London 2013. Using Impress.js, HTML, CSS',
		techstack: 'HTML, CSS, JS, Impress.js',
		image: '/images/tvhackfest-card.png',
		desktopImg: '/images/tvhackfest.png',
		mobile: false,
		tablet: false,
		desktop: true,
		link: 'http://alexincarnati.com/projects/tv/#/tvhackfest',
		status: 'available'
	},
	project23: {
		name: 'GAMBERO ROSSO COMPETITION',
		brand: 'GAMBERO ROSSO',
		company: 'CATENATE',
		year: '2012',
		desc:
			'Development of a webpage for Gambero Rosso for uploading recipes and cooking tips to www.gamberorosso.it webportal.  Using mainly HTML, JS, CSS, PHP / MySQL.',
		techstack: 'HTML, CSS, JS, PHP, MySQL',
		image: '/images/gambero-rosso-card.jpg',
		desktopImg: '/images/gambero-rosso.png',
		mobile: false,
		tablet: false,
		desktop: true,
		status: 'available'
	},
	project24: {
		name: 'STUDIO ROTONDO',
		brand: 'STUDIO ROTONDO',
		company: 'Freelance',
		year: '2012',
		desc:
			'Development and design of a Joomla based website for Studio Rotondo Chartered Accountants in Rome. Using mainly PHP, HTML, JS, CSS and integrating on Joomla CMS.',
		techstack: 'PHP, HTML, CSS, JS, Joomla CMS',
		image: '/images/studiorotondo-card.png',
		desktopImg: '/images/studiorotondo.png',
		mobile: false,
		tablet: false,
		desktop: true,
		link: 'http://alexincarnati.com/projects/rotondo2/',
		status: 'available'
	},
	project25: {
		name: 'ESYMAIL EMAIL MARKETING & SMS',
		brand: 'SMARTGAP',
		company: 'Freelance',
		year: '2012',
		desc:
			'UI / UX design of an email campaign marketing tool for SmartGap. Design using Adobe Photoshop and also performed usability testing on several SmartGap products.',
		techstack: 'Adobe Photoshop, Illustrator, UX, Usability Testing',
		image: '/images/esymail-card.png',
		desktopImg: '/images/esymail.png',
		mobile: false,
		tablet: false,
		desktop: true,
		link: 'http://www.smartgap.it/ita_97_EsyMail.html',
		status: 'available'
	},
	project26: {
		name: 'LA CUPOLA B&B',
		brand: 'LA CUPOLA B&B',
		company: 'Freelance',
		year: '2006',
		desc:
			'Development and design of a multi-language website for La Cupola Bed and Breakfast, a boutique bed&breakfast in the city center of Rome. Mainly using HTML, JS, CSS.',
		techstack: 'HTML, CSS, JS',
		image: '/images/lacupola-card.png',
		desktopImg: '/images/lacupola.png',
		mobile: false,
		tablet: false,
		desktop: true,
		link: 'http://alexincarnati.com/projects/cupolabb.com/index.htm',
		status: 'available'
	}
};

export default projects;
