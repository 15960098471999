import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class ScrollToTop extends React.Component {

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	static propTypes = {
		location: PropTypes.object,
		children: PropTypes.object
	};

	render() {
		return (this.props.children);
	}

}

export default withRouter(ScrollToTop);
