import React from 'react';
import LogoSvg from './LogoSvg';

export const LoaderSvg = () => {

    return ( 
        <div className="loader-ellipsis-wrapper">
            <LogoSvg theme="light" />
            <div className="loader-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
     );
}
 
export default LoaderSvg;