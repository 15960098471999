import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoaderSvg from './LoaderSvg';
import projectData from '../projectData';
import ScrollToTop from './RouterBase';

const App = lazy(() => import('./App'));
const Portfolio = lazy(() => import('./Portfolio'));
const NotFound = lazy(() => import('./NotFound'));
const Offduty = lazy(() => import('./Offduty'));
const Details = lazy(() => import('./Details'));
const Contact = lazy(() => import('./Contact'));
const About = lazy(() => import('./About'));

class Router extends React.Component {
	state = {
		projects: projectData
	};

	render() {
		const totalProjects = Object.keys(projectData).length;

		return (
			<BrowserRouter>
			<Suspense fallback={<LoaderSvg />}>
				<ScrollToTop>
					<Switch>
						<Route exact path="/" render={() => <App />} />
						<Route exact path="/portfolio" render={() => <Portfolio {...this.state} />} />
						<Route
							path="/portfolio-:projectId"
							render={(props) => (
								<Details
									{...props}
									totalProjects={totalProjects}
									currentProject={Number(props.match.params.projectId.match(/(\d+)/g)[0])}
									nextLink={
										props.match.params.projectId.match(/(\d+)/g)[0] < totalProjects &&
										props.match.params.projectId.match(/(\d+)/g)[0] >= 1 ? (
											Number(props.match.params.projectId.match(/(\d+)/g)[0]) + 1
										) : (
											Number(props.match.params.projectId.match(/(\d+)/g)[0])
										)
									}
									prevLink={
										props.match.params.projectId.match(/(\d+)/g)[0] <= totalProjects &&
										props.match.params.projectId.match(/(\d+)/g)[0] > 1 ? (
											Number(props.match.params.projectId.match(/(\d+)/g)[0]) - 1
										) : (
											Number(props.match.params.projectId.match(/(\d+)/g)[0])
										)
									}
									details={this.state.projects[props.match.params.projectId]}
								/>
							)}
						/>
						<Route exact path="/Offduty" render={() => <Offduty />} />
						<Route exact path="/contact" render={() => <Contact />} />
						<Route exact path="/about" render={() => <About />} />
						<Route path="*" render={() => <NotFound />} />
					</Switch>
				</ScrollToTop>
				</Suspense>
			</BrowserRouter>
		);
	}
}

export default Router;
